import React, { useState } from "react";
import Wrapper from "../../common/popup/Wrapper";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { API } from "../../../config/api";
import Alert from "../../common/popup/Alert";
import { saveUserData } from "../../../utils/userData";

export default function Wallet2FA({
  state,
  id,
  session,
  onClose,
  setSession,
  setUser,
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [alert, setAlert] = useState("");

  const getNewCode = async () => {
    setSending(true);
    try {
      const res = await API.post(`/user/${id}/update-wallet`, state);
      setSession(res.data?.sessionhash);
      setSending(false);
    } catch (err) {
      setSending(false);
      if (err?.response?.data) {
        setError(err?.response?.data);
      } else {
        setError("Something went wrong, please try again!");
      }
    }
  };

  const verifyCode = async () => {
    if (!code) {
      setError("6-Digits code required");
    } else {
      setLoading(true);
      setError(null);
      const data = {
        ...state,
        code: code,
        sessionhash: session,
      };

      try {
        const res = await API.post(`/user/${id}/update-wallet`, data);

        const userRes = await API.get(`/user/${id}`);

        saveUserData(userRes.data);
        setUser(userRes.data);
        setLoading(false);
        setAlert(`You have succussfully registered your wallet.`);
      } catch (err) {
        setLoading(false);
        if (err?.response?.status === 400) {
          setError("You have entered incorrect code");
        } else {
          setError("Something went wrong, try again!");
        }
      }
    }
  };

  return (
    <Wrapper onClose={onClose}>
      <div className="pt-[56px] px-5 sm:px-[76px] pb-[70px]">
        <p className="font-normal text-2xl sm:text-[32px] text-white leading-[48px]">
          Need User Authorization
        </p>
        <p className="font-medium mt-5 tracking-[0.135em] text-xs sm:text-sm text-[#606164] leading-[21px]">
          Your wallet address has been validated. Enter code and press confirm
          to complete the registration.
        </p>
        <p className="font-normal mt-6 max-w-[441px] tracking-[0.135em] text-xs sm:text-sm text-[#606164] leading-[21px]">
          A verification code has been sent to your email. If you did not
          recieve it, please check your spam/junk folder. Otherwise{" "}
          <span className="cursor-pointer underline" onClick={getNewCode}>
            {sending ? "Sending..." : "Get new code"}
          </span>
        </p>
        <div className="mt-[25px] max-w-[326px] mb-[26px]">
          <Input
            placeholder={"6-Digits code"}
            type="text"
            value={code}
            mt="mt-[9px]"
            maxLength={6}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        {error !== null && (
          <span className="text-red-700 opacity-80 mb-2  block font-bold text-sm">
            {error}
          </span>
        )}
        <Button
          text={loading ? "Loading..." : "Confirm"}
          width="w-[170px]"
          disable={loading}
          onClick={verifyCode}
        />
      </div>
      {alert !== "" && <Alert message={alert} onClose={onClose} />}
    </Wrapper>
  );
}
