import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Options from "../components/profile/Options";
import ContactInfo from "../components/profile/ContactInfo";
import Security from "../components/profile/Security";
import BillingInfo from "../components/profile/BillingInfo";
import Wallet from "../components/profile/Wallet";
import { AuthContext } from "../context/authContext";
import { isLoggedIn, getUserData } from "../utils/userData";

export default function Profile({ location }) {
  const { user, setUser } = useContext(AuthContext);
  const [selected, setSelected] = useState("0");
  const [checkAuth, setCheckAuth] = useState(true);

  useEffect(() => {
    if (isLoggedIn() && getUserData()) {
      setCheckAuth(false);
    } else {
      navigate("/");
    }
    if (location.state?.active) {
      setSelected("3");
    }
  }, []);

  if (checkAuth) return <div></div>;

  return (
    <Layout>
      <div className="relative pb-11 sm:pb-[350px]">
        <div className="absolute top-0 right-0 bottom-0 left-0 -z-10">
          <StaticImage
            src="../images/purple-center.webp"
            alt="logo"
            className="centerbg"
          />
        </div>
        <div className="mt-10 md:mt-[191px] max-w-[1366px] mx-auto mb-10">
          <div className="grid grid-cols-1 md:grid-cols-12 md:gap-4 lg:gap-[34px] pl-5 lg:pl-[94px] pr-5 lg:pr-[96px]">
            <div className="col-span-4 md:h-[849px] bg-[#16171B] md:border-[0.3px] border-white/30 rounded-[28px] mb-3 md:mb-0">
              <Options
                selected={selected}
                onSelect={(val) => setSelected(val)}
              />
            </div>
            <div className="col-span-8">
              {selected === "0" && (
                <ContactInfo data={user} setUser={setUser} />
              )}
              {selected === "1" && (
                <BillingInfo user={user} setUser={setUser} />
              )}
              {selected === "2" && <Security user={user} setUser={setUser} />}
              {selected === "3" && (
                <Wallet id={user?.id} wallet={user?.wallet} setUser={setUser} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
