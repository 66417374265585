import React, { useState, useEffect } from "react";
import Button from "../common/Button";
import axios from "axios";
import { API } from "../../config/api";
import { EditBig } from "../svgs";
import Wallet2FA from "./popup/Wallet2FA";

export default function Wallet({ id, wallet, setUser }) {
  const [address, setAddress] = useState(wallet ? wallet : "");
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [copied, setCopied] = useState(false);
  const [faPopup, setFAPopup] = useState(false);
  const [session, setSession] = useState("");

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  // const togglePopup = () => {
  //   setPopup(!popup);
  // };

  const handleChange = (e) => {
    setError("");
    // if (wallet) return;
    const value = e.target.value;
    if (value === "") {
      setValidate(false);
    } else {
      setValidate(true);
    }
    setAddress(value);
  };

  const validateWallet = async () => {
    if (!address) {
      setError("Wallet address missing");
    } else {
      setLoading(true);
      setError(null);
      try {
        const res = await axios.post(
          "https://mainnet.gateway.pokt.network/v1/lb/6248e19a3bd808003a80fc61/v1/query/balance",
          { address: address }
        );

        if (res.data?.balance >= 0) {
          const res = await API.post(`/user/${id}/update-wallet`, {
            wallet: address,
          });
          setSession(res.data?.sessionhash);
          setFAPopup(true);
        } else {
          setError("Wallet address is invalid");
        }

        setLoading(false);
      } catch (err) {
        setError("Something went wrong");
        setLoading(false);
      }
    }
  };

  // const copyText = () => {
  //   var copyText = document.getElementById("pok-address");

  //   copyText.select();
  //   copyText.setSelectionRange(0, 99999);

  //   navigator.clipboard.writeText(copyText.value);
  //   setCopied(true);
  // };

  const openPorkWatch = () => {
    if (address) {
      window.open(`https://pokt.watch/address/${address}`, "_blank").focus();
    }
  };

  const onClosePopup = () => {
    setSession("");
    setEdit(false);
    setFAPopup(false);
    setValidate(false);
  };

  return (
    <div className="pb-[31px] bg-[#16171B] border-[0.3px] border-white/30 rounded-[28px]">
      <p className="text-white pt-[68px] max-w-[673px] px-5 sm:pl-[42px] pb-8 text-base leading-[26px]">
        This is the address of your offline POKT wallet. Please ensure the address that you entered is correct and in your custody. Also confirm that this is not an exchange wallet. POKT sent to this wallet might be unrecoverable if it isn't your wallet.
      </p>
      <div className="relative pl-5 sm:pl-[42px] pr-5 sm:pr-[47px] bg-[#D9D9D9]/[0.03] pt-[14px]">
        {wallet && !edit ? (
          <>
            <span className="block text-sm leading-6 text-[#5C5D5F]">
              Please provide your POKT wallet address below.
            </span>
            <div className="relative flex items-center justify-between mt-[7px] pb-[15px]">
              <p className="text-sm sm:text-[18px] leading-[18px] truncate max-w-[500px] font-medium text-white">
                {wallet}
              </p>
              {/* <span onClick={() => setEdit(true)} className="cursor-pointer">
                <EditBig />
              </span> */}
            </div>
            <Button
              text={"pokt.watch"}
              width="w-[170px]"
              tailwind=""
              onClick={openPorkWatch}
            />
          </>
        ) : (
          <>
            <p className="text-md leading-6 text-[#fff] mb-2">
              Please provide your Pokt wallet address bellow.
            </p>
            <p className="text-sm leading-[21px] text-[#a2a3a6]">
              The address you are entering can not be an exchange address. You
              must have full control and custody of this address.
            </p>
            {validate && (
              <p className="text-sm leading-[21px] text-[#a2a3a6]">
                You can view your entered address on pokt.watch in new tab
                before validate
              </p>
            )}
            <div className="flex relative items-center mb-6 justify-between mt-[10px] border border-[#C4C4C4] rounded-[25px] py-[14px] pl-[28px] pr-[15px]">
              <div className="flex items-center w-full">
                <label
                  htmlFor="pok-address"
                  className="text-sm leading-[18px] font-medium text-white"
                >
                  Address
                </label>
                <input
                  placeholder="Enter here..."
                  id="pok-address"
                  value={address}
                  // disabled={wallet ? true : false}
                  onChange={handleChange}
                  className="text-sm leading-[18px] ml-4 xs:ml-[27px] font-medium text-white bg-transparent outline-none truncate w-[100px]  xs:w-full"
                />
              </div>

              {/* {edit && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="#606164"
                  className=" cursor-pointer"
                  onClick={() => setEdit(false)}
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              )} */}
              {/* <svg
                width="28"
                height="23"
                viewBox="0 0 28 23"
                fill="none"
                className="cursor-pointer"
                onClick={copyText}
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1_777_9660" fill="white">
                  <rect y="9" width="18.5108" height="14" rx="1" />
                </mask>
                <rect
                  y="9"
                  width="18.5108"
                  height="14"
                  rx="1"
                  stroke="#5C5D5F"
                  strokeWidth="3"
                  mask="url(#path-1-inside-1_777_9660)"
                />
                <rect
                  x="7.18335"
                  y="1.25"
                  width="20.0108"
                  height="15.5"
                  rx="1.75"
                  fill="#1C1D21"
                  stroke="#1C1D21"
                  strokeWidth="1.5"
                />
                <mask id="path-3-inside-2_777_9660" fill="white">
                  <rect x="7.93335" y="2" width="18.5108" height="14" rx="1" />
                </mask>
                <rect
                  x="7.93335"
                  y="2"
                  width="18.5108"
                  height="14"
                  rx="1"
                  fill="#1C1D21"
                  stroke="#5C5D5F"
                  strokeWidth="3"
                  mask="url(#path-3-inside-2_777_9660)"
                />
              </svg>
              <span
                className={`absolute transition-opacity duration-300 ${
                  copied ? "opacity-90" : "opacity-0"
                } right-3  -bottom-7 text-white text-sm`}
              >
                Copied!
              </span> */}
            </div>

            {error !== null && (
              <span className="text-red-700 mb-2 opacity-80 block font-bold text-sm">
                {error}
              </span>
            )}
            <div className="flex items-center">
              {validate && (
                <Button
                  text={loading ? "Loading..." : "VALIDATE"}
                  width="w-[170px]"
                  disable={loading}
                  outline={"secondary"}
                  onClick={validateWallet}
                />
              )}
              {address && (
                <Button
                  text={"pokt.watch"}
                  width="w-[170px]"
                  tailwind="ml-3"
                  onClick={openPorkWatch}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="relative pl-5 sm:pl-[42px] pr-5 sm:pr-[47px] pt-6 pb-6">
        <div className="flex items-center w-full">
          <p className="text-sm leading-[22px] text-[#eee]">
            If you would like to change your wallet address please contact us to make this change.
          </p>
        </div>
      </div>

      {faPopup && (
        <Wallet2FA
          id={id}
          state={{ wallet: address }}
          setSession={setSession}
          session={session}
          onClose={onClosePopup}
          setUser={setUser}
        />
      )}
    </div>
  );
}
