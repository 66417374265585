import React, { useState, useEffect } from "react";
import { EditBig } from "../svgs";
import { saveUserData } from "../../utils/userData";
import { API } from "../../config/api";
import Alert from "../common/popup/Alert";

export default function SocialLinks({ id, setUser, telegram, discord }) {
  const [telegramTxt, setTelegram] = useState("");
  const [discordTxt, setDiscord] = useState("");
  const [editTelegram, setEditTelegram] = useState(true);
  const [editDiscord, setEditDiscord] = useState(true);
  const [loadingTel, setLoadingTel] = useState(false);
  const [loadingDis, setLoadingDis] = useState(false);
  const [popup, setPopup] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (telegram) {
      setEditTelegram(false);
      setTelegram(telegram);
    }
    if (discord) {
      setEditDiscord(false);
      setDiscord(discord);
    }
  }, [telegram, discord]);

  const changeTelegram = (evt) => {
    const value = evt.target.value;
    setTelegram(value);
  };

  const changeDiscord = (evt) => {
    const value = evt.target.value;
    setDiscord(value);
  };

  const saveTelegram = async () => {
    if (!telegramTxt) {
      setError("Empty telegram text field");
    } else {
      setLoadingTel(true);
      setError(null);

      try {
        await API.put(`/user/${id}/update-userinfo`, { telegram: telegramTxt });
        const userRes = await API.get(`/user/${id}`);

        saveUserData(userRes.data);
        setUser(userRes.data);
        setPopup("Telegram successfully updated!");

        setLoadingTel(false);
      } catch (err) {
        setLoadingTel(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        }
      }
    }
  };

  const saveDiscord = async () => {
    if (!discordTxt) {
      setError("Empty discord text field");
    } else {
      setLoadingDis(true);
      setError(null);

      try {
        await API.put(`/user/${id}/update-userinfo`, { discord: discordTxt });
        const userRes = await API.get(`/user/${id}`);

        saveUserData(userRes.data);
        setUser(userRes.data);
        setPopup("Discord successfully updated!");

        setLoadingDis(false);
      } catch (err) {
        setLoadingDis(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        }
      }
    }
  };

  return (
    <div className="mt-[59px] bg-[#D9D9D9]/[0.03] pt-[14px] px-2 sm:px-[42px] pb-[33px]">
      <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
        Social Media (optional)
      </p>
      <div className="flex items-center justify-between mt-[19px]">
        <div className="flex items-center ml-3 w-full">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.51759 14.1381L1.74855 12.5372C0.717611 12.1982 0.711884 11.4337 1.97956 10.885L20.5613 3.15837C21.6399 2.68573 22.2508 3.28373 21.9015 4.7818L18.738 20.8516C18.5166 21.9962 17.877 22.2696 16.9892 21.7414L12.119 17.8617L9.84905 20.2208C9.61613 20.4632 9.42713 20.6708 9.06821 20.7222C8.7112 20.7756 8.41719 20.6605 8.20146 20.0235L6.5405 14.1237L6.51759 14.1381Z"
              fill="white"
            />
          </svg>
          {editTelegram ? (
            <input
              placeholder="Telegram"
              className="border-[#C4C4C4] outline-none text-white text-sm border rounded-[25px] bg-transparent ml-2 sm:ml-4 h-[40px] sm:h-[50px] px-3 sm:px-[21px]  w-full max-w-[347px]"
              value={loadingTel ? "Loading..." : telegramTxt}
              onChange={changeTelegram}
            />
          ) : (
            <span className="block text-xs xs:text-[18px] text-white leading-[27px] tracking-[0.135em] ml-2 sm:ml-[17px]">
              {telegram}
            </span>
          )}
        </div>
        {editTelegram && telegramTxt.length > 2 && (
          <div className="flex items-center ml-2 xs:ml-0">
            <button
              className="txt-gradient-yellow cursor-pointer mr-1"
              disabled={loadingTel}
              onClick={saveTelegram}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#606164"
                viewBox="0 0 16 16"
              >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
              </svg>
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#606164"
              className=" cursor-pointer"
              onClick={() => setEditTelegram(false)}
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        )}
        {!editTelegram && (
          <div className="cursor-pointer" onClick={() => setEditTelegram(true)}>
            <EditBig />
          </div>
        )}
      </div>

      <div className="flex items-center justify-between mt-[33px]">
        <div className="flex items-center ml-3 w-full">
          <svg
            width="25"
            height="18"
            viewBox="0 0 25 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.3538 15.6878C16.0202 15.7676 15.6843 15.8383 15.3467 15.9C13.5143 16.2383 11.6329 16.2314 9.80315 15.8796C9.48269 15.8184 9.02905 15.7102 8.44431 15.5572L6.5278 18C1.7979 17.851 0 14.7429 0 14.7429C0 7.84295 3.08182 2.24913 3.08182 2.24913C6.16364 -0.0630949 9.09564 0.000170302 9.09564 0.000170302L9.93425 1.07159C10.0115 1.06096 10.0893 1.05415 10.1673 1.05118C11.7659 0.843368 13.3859 0.850235 14.9825 1.07159L15.9023 0.000170302C15.9023 0.000170302 18.8364 -0.0630949 21.9161 2.25117C21.9161 2.25117 25 7.84295 25 14.7429C25 14.7429 23.1792 17.849 18.4493 18L16.3538 15.6878ZM8.36732 8.38172C7.1167 8.38172 6.12827 9.30008 6.12827 10.4225C6.12827 11.545 7.13751 12.4633 8.36732 12.4633C9.61587 12.4633 10.6043 11.545 10.6043 10.4225C10.6251 9.30008 9.61795 8.38172 8.36732 8.38172ZM16.3746 8.38172C15.1261 8.38172 14.1377 9.30008 14.1377 10.4225C14.1377 11.545 15.1469 12.4633 16.3746 12.4633C17.6253 12.4633 18.6137 11.545 18.6137 10.4225C18.6137 9.30008 17.6253 8.38172 16.3746 8.38172Z"
              fill="white"
            />
          </svg>

          {editDiscord ? (
            <input
              placeholder="Discord"
              className="border-[#C4C4C4] outline-none text-white text-sm border rounded-[25px] bg-transparent ml-2 sm:ml-4 h-[40px] sm:h-[50px] px-3 sm:px-[21px]  w-full max-w-[347px]"
              value={loadingDis ? "Loading..." : discordTxt}
              onChange={changeDiscord}
            />
          ) : (
            <span className="block text-sm sm:text-[18px] text-white leading-[27px] tracking-[0.135em] ml-[17px]">
              {discord}
            </span>
          )}
        </div>
        {editDiscord && discordTxt.length > 2 && (
          <div className="flex items-center ml-2 xs:ml-0">
            <button
              className="txt-gradient-yellow cursor-pointer mr-1"
              disabled={loadingDis}
              onClick={saveDiscord}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#606164"
                viewBox="0 0 16 16"
              >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
              </svg>
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#606164"
              className=" cursor-pointer"
              onClick={() => setEditDiscord(false)}
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        )}
        {!editDiscord && (
          <div className="cursor-pointer" onClick={() => setEditDiscord(true)}>
            <EditBig />
          </div>
        )}
      </div>
      {error !== null && (
        <span className="text-red-700 opacity-80 mt-2  block font-bold text-sm">
          {error}
        </span>
      )}
      {popup !== "" && <Alert message={popup} onClose={() => setPopup("")} />}
    </div>
  );
}
