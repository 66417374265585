import React from "react";
import "./style.css";

export default function Switch({ checked, onClick, loading }) {
  return (
    <div
      className={`relative rounded-2xl transition-colors duration-300 ${
        checked ? "bg-[#5324b0]" : "bg-[#aeaeae]"
      }  w-[49px] h-[30px] cursor-pointer`}
      onClick={onClick}
    >
      <div
        className={`absolute transition-all ${
          checked ? "ml-[19px]" : "ml-0"
        } duration-300 h-[28px] w-[28px] rounded-full bg-[#ededed] left-[1px] top-[1px]`}
      />
      {loading && (
        <span className="text-white text-[9px] top-8 left-2 absolute">
          Loading
        </span>
      )}
      {/* <input
        type="checkbox"
        className="ios-switch"
        id="checkbox"
        onChange={onClick}
        checked={checked}
        // defaultChecked={checked}
      /> */}
      {/* <label htmlFor="checkbox"></label> */}
    </div>
  );
}
