import React, { useState } from "react";
import { EditBig, MasterCard, VisaCard } from "../svgs";
import { Billing } from "./popup";
import { hideCardNumber, cardType } from "../../utils/common";

export default function BillingInfo({ user, setUser }) {
  // const [active, setActive] = useState(0);
  const [billingPopup, setBillingPopup] = useState(false);
  const [method, setMethod] = useState("add");
  const [editBilling, setEditBilling] = useState({});

  // toggle for edit popup
  const toggleBilling = (val, billing) => {
    setMethod(val);
    setEditBilling(billing);
    setBillingPopup(!billingPopup);
  };

  return (
    <div className="pt-[49px] pb-[31px] bg-[#16171B] border-[0.3px] border-white/30 rounded-[28px]">
      {user?.billing?.length ? (
        user?.billing.map((item, index) => {
          return (
            <div
              key={index}
              className="relative mb-[19px] flex items-center justify-between px-3 sm:pl-[42px] sm:pr-[47px]  bg-[#D9D9D9]/[0.03] py-[14px]"
            >
              <div>
                <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
                  Payment Method
                </p>
                <div className="flex items-center mt-[7px]">
                  {cardType(item?.card_number) === "master" ? (
                    <MasterCard />
                  ) : (
                    <VisaCard />
                  )}
                  <p className="text-xs sm:text-base ml-[14px] lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
                    {hideCardNumber(item?.card_number)}
                  </p>
                </div>
              </div>
              <p className="absolute right-[20%] top-[40%] text-xs ml-[14px] md:text-base leading-6 text-white">
                {item?.active && "(Active)"}
              </p>
              <div
                className="cursor-pointer"
                onClick={() => toggleBilling("edit", item)}
              >
                <EditBig />
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-base text-center uppercase leading-6 text-white">
          There is no billing yet
        </p>
      )}

      {/* Add Button */}
      <div className="flex justify-end mt-[10px] mr-[42px]">
        <div
          onClick={() => toggleBilling("add", {})}
          className="yellow-gradient h-[40px] w-[40px] rounded-full flex justify-center items-center cursor-pointer"
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="9.57129"
              y="0.285645"
              width="2.85714"
              height="22.1429"
              rx="1.42857"
              fill="white"
            />
            <rect
              x="22.4287"
              y="9.92871"
              width="2.85714"
              height="22.1429"
              rx="1.42857"
              transform="rotate(90 22.4287 9.92871)"
              fill="white"
            />
          </svg>
        </div>
      </div>
      {billingPopup && (
        <Billing
          onClose={toggleBilling}
          setUser={setUser}
          method={method}
          id={user?.id}
          billing={editBilling}
        />
      )}
    </div>
  );
}
