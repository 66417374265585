import React, { useState } from "react";
import { EditBig } from "../svgs";
import {
  EditName,
  EditEmail,
  EmailConfirm,
  EditPhone,
  EditCountry,
} from "./popup";
import SocialLinks from "./SocialLinks";

export default function ContactInfo({ data, setUser }) {
  const [namePopup, setNamePopup] = useState(false);
  const [email, setEmail] = useState("");
  const [session, setSession] = useState("");
  const [emailPopup, setEmailPopup] = useState(false);
  const [emailConfirmPopup, setEmailConfirmPopup] = useState(false);
  const [phonePopup, setPhonePopup] = useState(false);
  const [countryPopup, setCountryPopup] = useState(false);

  // toggle for edit popup
  const toggleEditName = () => {
    setNamePopup(!namePopup);
  };

  // toggle for email popup
  const toggleEditEmail = () => {
    setEmailPopup(!emailPopup);
  };

  // goto next step of enter received code
  const nextEmailChangeStep = () => {
    setEmailPopup(!emailPopup);
    setEmailConfirmPopup(true);
  };

  // toggle for confirm email popup
  const toggleEmailConfirm = () => {
    setEmailConfirmPopup(!emailConfirmPopup);
  };

  // toggle for edit phone popup
  const toggleEditPhone = () => {
    setPhonePopup(!phonePopup);
  };

  // toggle for country popup
  const toggleEditCountry = () => {
    setCountryPopup(!countryPopup);
  };

  return (
    <div className="pt-[25px] md:h-[849px] pb-[61px] bg-[#16171B] border-[0.3px] border-white/30 rounded-[28px]">
      {/* <div className="flex items-center justify-center">
        <div className="relative h-[134px] w-[134px]  bg-white rounded-full cursor-pointer">
          <svg
            width="134"
            height="134"
            viewBox="0 0 134 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M118.526 109.736C125.404 101.467 130.188 91.6654 132.472 81.1592C134.757 70.653 134.476 59.7515 131.652 49.377C128.828 39.0024 123.545 29.46 116.25 21.557C108.955 13.654 99.8615 7.62289 89.7401 3.97383C79.6186 0.324779 68.7665 -0.834845 58.1016 0.59306C47.4368 2.02096 37.273 5.99439 28.4701 12.1772C19.6671 18.36 12.4841 26.5703 7.52849 36.1136C2.5729 45.6569 -0.00939912 56.2523 2.57064e-05 67.0037C0.00364234 82.6331 5.51589 97.7619 15.5695 109.736L15.4737 109.817C15.809 110.219 16.1921 110.563 16.5369 110.961C16.9679 111.453 17.4325 111.918 17.8779 112.396C19.2188 113.851 20.5981 115.248 22.0444 116.559C22.485 116.961 22.94 117.334 23.3854 117.717C24.9179 119.038 26.4935 120.292 28.1266 121.459C28.3373 121.603 28.5289 121.789 28.7396 121.938V121.88C39.9563 129.767 53.3371 134 67.0527 134C80.7683 134 94.1491 129.767 105.366 121.88V121.938C105.577 121.789 105.763 121.603 105.979 121.459C107.607 120.287 109.188 119.038 110.72 117.717C111.165 117.334 111.62 116.956 112.061 116.559C113.507 115.243 114.887 113.851 116.228 112.396C116.673 111.918 117.133 111.453 117.569 110.961C117.909 110.563 118.296 110.219 118.632 109.812L118.526 109.736ZM67.0479 28.722C71.3103 28.722 75.477 29.9849 79.0211 32.3511C82.5651 34.7172 85.3274 38.0803 86.9585 42.015C88.5897 45.9497 89.0165 50.2793 88.1849 54.4564C87.3534 58.6335 85.3008 62.4704 82.2868 65.4819C79.2729 68.4934 75.4328 70.5443 71.2523 71.3751C67.0718 72.206 62.7386 71.7796 58.8007 70.1497C54.8627 68.5199 51.4969 65.7599 49.1288 62.2188C46.7608 58.6776 45.4968 54.5144 45.4968 50.2555C45.4968 44.5444 47.7674 39.0673 51.809 35.029C55.8506 30.9907 61.3322 28.722 67.0479 28.722ZM28.7684 109.736C28.8514 103.452 31.4069 97.4546 35.8819 93.0397C40.357 88.6247 46.3917 86.1477 52.6805 86.1445H81.4153C87.7042 86.1477 93.7389 88.6247 98.2139 93.0397C102.689 97.4546 105.244 103.452 105.327 109.736C94.8241 119.193 81.1867 124.427 67.0479 124.427C52.9091 124.427 39.2717 119.193 28.7684 109.736Z"
              fill="url(#paint0_linear_877_5519)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_877_5519"
                x1="125"
                y1="19.1429"
                x2="-3.20075"
                y2="41.0432"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7B7B7B" />
                <stop offset="1" stopColor="#4F4F4F" />
              </linearGradient>
            </defs>
          </svg>

          <div className="absolute bottom-0  right-0 h-[44px] w-[44px] bg-[#1C1D21] rounded-full flex items-center justify-center">
            <EditSmall />
          </div>
        </div>
      </div> */}
      <div className="mt-7">
        <div className="flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
              Username
            </p>
            <p className="text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
              {data?.username ? data?.username : "--- --- ---"}
            </p>
          </div>
        </div>
        <div className="mt-[19px] flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
              Name
            </p>
            <p className="text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
              {data?.first_name} {data?.last_name}
            </p>
          </div>
          <div className="cursor-pointer" onClick={toggleEditName}>
            <EditBig />
          </div>
        </div>
        <div className="mt-[19px] flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-baseleading-6 text-white/30">
              Email
            </p>
            <p className="text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
              {data?.email ? data?.email : "--- --- ---"}
            </p>
          </div>
          <div className="cursor-pointer" onClick={toggleEditEmail}>
            <EditBig />
          </div>
        </div>
        <div className="mt-[19px] flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
              Phone Number
            </p>
            <p className="text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
              {data?.phonenumber ? data?.phonenumber : "--- --- ---"}
            </p>
          </div>
          <div className="cursor-pointer" onClick={toggleEditPhone}>
            <EditBig />
          </div>
        </div>
        <div className="mt-[19px] flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
              Country/State
            </p>
            <p className="text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
              {data?.country ? data?.country : "-----"}
            </p>
          </div>
          <div className="cursor-pointer" onClick={toggleEditCountry}>
            <EditBig />
          </div>
        </div>
      </div>
      <SocialLinks
        id={data?.id}
        telegram={data?.telegram}
        discord={data?.discord}
        setUser={setUser}
      />
      {namePopup && (
        <EditName
          onClose={toggleEditName}
          id={data?.id}
          first_name={data?.first_name}
          last_name={data?.last_name}
          setUser={setUser}
        />
      )}
      {emailPopup && (
        <EditEmail
          onClose={toggleEditEmail}
          onNext={nextEmailChangeStep}
          email={data?.email}
          id={data?.id}
          setEmail={setEmail}
          setSession={setSession}
        />
      )}
      {emailConfirmPopup && (
        <EmailConfirm
          onClose={() => setEmailConfirmPopup(false)}
          session={session}
          email={email}
          id={data?.id}
          setUser={setUser}
        />
      )}
      {phonePopup && (
        <EditPhone
          onClose={toggleEditPhone}
          id={data?.id}
          setUser={setUser}
          userPhone={data?.phonenumber}
        />
      )}

      {countryPopup && (
        <EditCountry
          onClose={toggleEditCountry}
          id={data?.id}
          setUser={setUser}
          userCountry={data?.country}
        />
      )}
    </div>
  );
}
