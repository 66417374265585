import React from "react";
import Select from "../common/select";
import { useWindowSize } from "../../hooks/windowSize";

export default function Options({ selected, onSelect }) {
  const { width } = useWindowSize();
  return (
    <div className="md:pt-[56px] md:pb-[35px]">
      {width > 767 ? (
        <>
          <div
            className={`flex items-center py-[27px] px-3 lg:px-[22px] cursor-pointer ${
              selected === "0" && "bg-[#D9D9D9]/[0.04]"
            } `}
            onClick={() => onSelect("0")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM4 5V19H20V5H4ZM6 7H12V13H6V7ZM8 9V11H10V9H8ZM6 15H18V17H6V15ZM14 7H18V9H14V7ZM14 11H18V13H14V11Z"
                fill={
                  selected === "0"
                    ? "url(#paint0_linear_144_597)"
                    : "rgba(255,255,255,0.2)"
                }
              />

              <defs>
                <linearGradient
                  id="paint0_linear_144_597"
                  x1="12"
                  y1="3"
                  x2="12"
                  y2="28.74"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFC201" />
                  <stop offset="1" stopColor="#FF6301" />
                </linearGradient>
              </defs>
            </svg>
            <span className="block text-white text-base lg:text-[20px] leading-[30px] font-normal ml-[14px]">
              Contact Info
            </span>
          </div>
          <div
            className={`flex items-center py-[27px] px-3 lg:px-[22px] cursor-pointer ${
              selected === "1" && "bg-[#D9D9D9]/[0.04]"
            } `}
            onClick={() => onSelect("1")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className="text-red-800"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                fill={
                  selected === "1"
                    ? "url(#paint0_linear_144_597)"
                    : "rgba(255,255,255,0.2)"
                }
              />
              <defs>
                <linearGradient
                  id="paint0_linear_144_597"
                  x1="12"
                  y1="3"
                  x2="12"
                  y2="28.74"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFC201" />
                  <stop offset="1" stopColor="#FF6301" />
                </linearGradient>
              </defs>
            </svg>

            <span className="block text-white text-base lg:text-[20px] leading-[30px] font-normal ml-[14px]">
              Billing Info
            </span>
          </div>
          <div
            className={`flex items-center py-[27px] px-3 lg:px-[22px] cursor-pointer ${
              selected === "2" && "bg-[#D9D9D9]/[0.04]"
            } `}
            onClick={() => onSelect("2")}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.783 2.826L12 1L20.217 2.826C20.4391 2.87536 20.6377 2.99897 20.78 3.1764C20.9224 3.35384 21 3.57452 21 3.802V13.789C20.9999 14.7767 20.756 15.7492 20.2899 16.62C19.8238 17.4908 19.1499 18.2331 18.328 18.781L12 23L5.672 18.781C4.85027 18.2332 4.17646 17.4911 3.71035 16.6205C3.24424 15.7498 3.00024 14.7776 3 13.79V3.802C3.00004 3.57452 3.07764 3.35384 3.21999 3.1764C3.36234 2.99897 3.56094 2.87536 3.783 2.826ZM5 4.604V13.789C5.00001 14.4475 5.16257 15.0957 5.47326 15.6763C5.78395 16.2568 6.23315 16.7517 6.781 17.117L12 20.597L17.219 17.117C17.7667 16.7518 18.2158 16.2571 18.5265 15.6767C18.8372 15.0964 18.9998 14.4483 19 13.79V4.604L12 3.05L5 4.604Z"
                fill={
                  selected === "2"
                    ? "url(#paint0_linear_144_597)"
                    : "rgba(255,255,255,0.2)"
                }
              />
              <defs>
                <linearGradient
                  id="paint0_linear_144_597"
                  x1="12"
                  y1="3"
                  x2="12"
                  y2="28.74"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFC201" />
                  <stop offset="1" stopColor="#FF6301" />
                </linearGradient>
              </defs>
            </svg>

            <span className="block text-white text-base lg:text-[20px] leading-[30px] font-normal ml-[14px]">
              Security
            </span>
          </div>
          <div
            className={`flex items-center py-[27px] px-3 lg:px-[22px] cursor-pointer ${
              selected === "3" && "bg-[#D9D9D9]/[0.04]"
            } `}
            onClick={() => onSelect("3")}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.83337 7.33366H20.1667M1.83337 5.50033C1.83337 5.0141 1.83337 3.66699 1.83337 3.66699C1.83337 3.66699 3.18048 3.66699 3.66671 3.66699H18.3334C18.8196 3.66699 20.1667 3.66699 20.1667 3.66699C20.1667 3.66699 20.1667 5.0141 20.1667 5.50033V16.5003C20.1667 16.9866 20.1667 18.3337 20.1667 18.3337C20.1667 18.3337 18.8196 18.3337 18.3334 18.3337H3.66671C3.18048 18.3337 1.83337 18.3337 1.83337 18.3337C1.83337 18.3337 1.83337 16.9866 1.83337 16.5003V5.50033Z"
                stroke={
                  selected === "3"
                    ? "url(#paint0_linear_144_597)"
                    : "rgba(255,255,255,0.2)"
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.83337 11H8.25004C8.25004 11.9167 8.80004 13.75 11 13.75C13.2 13.75 13.75 11.9167 13.75 11H20.1667"
                stroke={
                  selected === 3
                    ? "url(#paint0_linear_144_597)"
                    : "rgba(255,255,255,0.2)"
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_144_597"
                  x1="12"
                  y1="3"
                  x2="12"
                  y2="28.74"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFC201" />
                  <stop offset="1" stopColor="#FF6301" />
                </linearGradient>
              </defs>
            </svg>

            <span className="block text-white text-base lg:text-[20px] leading-[30px] font-normal ml-[14px]">
              Wallet
            </span>
          </div>
        </>
      ) : (
        <div className="">
          <Select
            onChange={(val) => onSelect(val.target.value)}
            value={selected}
          >
            <option value="0">Contact Info</option>
            <option value="1">Billing Info</option>
            <option value="2">Security</option>
            <option value="3">Wallet</option>
          </Select>
        </div>
      )}
    </div>
  );
}
