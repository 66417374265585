import React, { useState } from "react";
import Switch from "../common/switch";
import { EditEmail, EmailConfirm, ChangePassword } from "./popup";
import { EditBig } from "../svgs";
import { API } from "../../config/api";
import Alert from "../common/popup/Alert";
import { saveUserData, getUserData } from "../../utils/userData";
import Toggle2FA from "./popup/Toggle2FA";

export default function Security({ user, setUser }) {
  const [session, setSession] = useState("");
  const [email, setEmail] = useState("");
  const [emailPopup, setEmailPopup] = useState(false);
  const [emailConfirmPopup, setEmailConfirmPopup] = useState(false);
  const [pwdPopup, setPwdPopup] = useState(false);
  const [twoFactor, setTwoFactor] = useState(user?.enabled2fa);
  const [alert, setAlert] = useState("");
  const [faPopup, setFAPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  // toggle for email popup
  const toggleEditEmail = () => {
    setEmailPopup(!emailPopup);
  };

  // goto next step of enter received code
  const nextEmailChangeStep = () => {
    setEmailPopup(!emailPopup);
    setEmailConfirmPopup(true);
  };

  // toggle for confirm email popup
  const toggleEmailConfirm = () => {
    setEmailConfirmPopup(!emailConfirmPopup);
  };

  const handleEdit = () => {
    setEmailPopup(true);
  };
  // toggle for change password popup
  const toggleChangePwd = () => {
    setPwdPopup(!pwdPopup);
  };

  const toggleTwoFA = () => {
    if (faPopup) {
      setSession("");
    }
    setFAPopup(!faPopup);
  };

  const hanldeSwitch = async () => {
    let two = !twoFactor;
    // setTwoFactor(two);
    setLoading(true);

    try {
      const res = await API.put(`/user/${user?.id}/update-2fa`, {
        enable2fa: two,
      });

      setSession(res.data.sessionhash);
      setLoading(false);
      setFAPopup(true);
      // const newObj = { ...getUserData(), ...res.data };
      // setUser(newObj);
      // setAlert(`Two Factor ${two ? "Enable" : "Disable"} successfully`);
      // saveUserData(newObj);
    } catch (err) {
      setLoading(false);
      setAlert("There is something wrong!, Please try again");
    }
  };

  return (
    <div className="pt-[49px] pb-[90px] bg-[#16171B] border-[0.3px] border-white/30 rounded-[28px]">
      <div className="flex flex-col  pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
        <div className="flex justify-between border-b border-white/30">
          <div>
            <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
              2-Factor Verification
            </p>
            <div className="flex items-center mb-[26px] mt-2">
              <div className="flex items-center text-white text-base leading-6">
                <span>Email</span>
              </div>
            </div>
          </div>
          <Switch
            checked={twoFactor}
            onClick={hanldeSwitch}
            loading={loading}
          />
        </div>
        <div className="flex items-center mt-[19px] justify-between text-xs sm:text-base lg:text-[18px] leading-[27px] text-white tracking-[0.135em]">
          <span>{user?.email ? user.email : "--- --- ---"}</span>

          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            className="ml-[5px] cursor-pointer"
            onClick={handleEdit}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.41625 20.0601L20.7125 4.76255C21.3168 4.18426 22.1235 3.86556 22.9599 3.8747C23.7963 3.88384 24.5958 4.2201 25.1874 4.81146C25.7789 5.40282 26.1154 6.20228 26.1247 7.03866C26.1341 7.87503 25.8156 8.68184 25.2375 9.2863L9.93875 24.5838C9.58975 24.9328 9.14524 25.1707 8.66125 25.2676L3.75 26.2501L4.7325 21.3376C4.82931 20.8536 5.06722 20.4091 5.41625 20.0601V20.0601Z"
              stroke="#606164"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.125 8.125L21.875 11.875"
              stroke="#606164"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
      <div className="mt-[19px] flex items-center justify-between pl-[42px] pr-[47px] bg-[#D9D9D9]/[0.03] py-[14px]">
        <div>
          <p className="text-[10px] sm:text-sm lg:text-base leading-6 text-white/30">
            Password
          </p>
          <input
            type="password"
            defaultValue="########"
            className="text-xs sm:text-base lg:text-[18px] leading-[27px] bg-transparent outline-none active:outline-none border-none text-white tracking-[0.135em]"
          />
        </div>
        <div className="cursor-pointer" onClick={toggleChangePwd}>
          <EditBig />
        </div>
      </div>
      {emailPopup && (
        <EditEmail
          onClose={toggleEditEmail}
          onNext={nextEmailChangeStep}
          email={user?.email}
          id={user?.id}
          setEmail={setEmail}
          setSession={setSession}
        />
      )}
      {emailConfirmPopup && (
        <EmailConfirm
          onClose={toggleEmailConfirm}
          session={session}
          email={email}
          id={user?.id}
          setUser={setUser}
          setSession={setSession}
        />
      )}

      {pwdPopup && (
        <ChangePassword
          onClose={toggleChangePwd}
          username={user?.username}
          session={""}
        />
      )}
      {faPopup && (
        <Toggle2FA
          id={user?.id}
          state={{ enable2fa: !twoFactor }}
          setSession={setSession}
          session={session}
          saveUserData={saveUserData}
          setUser={setUser}
          onClose={toggleTwoFA}
          setTwoFactor={setTwoFactor}
          getUserData={getUserData}
        />
      )}
      {alert !== "" && <Alert message={alert} onClose={() => setAlert("")} />}
    </div>
  );
}
